/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Anchor } from 'antd';
const { Link } = Anchor;

import Head from '../components/Head';
import StandardPage from '../components/StandardPage';
import CTASection from '../components/CTASection';
import LearnMore from '../components/LearnMore';
import faqs from '../components/faqs';

const LetterSection = () => {
  console.log(faqs);
  const anchorStyle = { visibility: 'hidden', cursor: 'none' };
  return (
    <StandardPage>
      <Head
        title="Frequently Asked Questions About Direct Primary Care"
        description="We tell you everything you want to know about DPC: the costs, the benefits, and how it can change your life."
        url="/faq/"
        image="/img/banner.png"
      />
      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <a id="letter" style={anchorStyle} />
        <div className="row as jc wide">
          <div className="gutter">
            <Anchor offsetTop={150}>
              <Link href="#faq" title={'DPC FAQ'}>
                {Object.keys(faqs).map((faqKey, i) => {
                  const faq = faqs[faqKey];
                  return <Link key={i} href={`#${faq.link.slice(4)}`} title={faq.title} />;
                })}
              </Link>
              <Link href="#cta" title={'Getting started with DPC'} />
            </Anchor>
          </div>
          <div className="wide readable col js ac blog">
            <div style={{ height: '100px' }} />
            <a id="faq" style={anchorStyle} />
            <img src="/img/learn.svg" style={{ width: '200px' }} alt="Celebration illustration" />
            <h1 className="title">Frequently Asked Questions</h1>

            {Object.keys(faqs).map((faqKey, i) => {
              const faq = faqs[faqKey];
              return (
                <div key={i} className="wide">
                  <div style={{ height: '50px' }} />
                  <a id={faq.link.slice(4)} style={anchorStyle} />
                  <h1 className="subsubtitle">{faq.title}</h1>
                  {faq.body}
                </div>
              );
            })}

            <div style={{ height: '100px' }} />
            <CTASection />
            <div style={{ height: '100px' }} />
            <LearnMore home crisis market />
          </div>
          <div className="gutter" />
        </div>
      </div>
    </StandardPage>
  );
};

export default LetterSection;
